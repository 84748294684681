import React, { useMemo, useCallback } from 'react';
import { FaGithub, FaLinkedin, FaEnvelope, FaLanguage } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import profileImage from './assets/images/avatar.jpg';
import getirfinansLogo from './assets/images/getirfinans.jpeg';
import comodoLogo from './assets/images/comodo.jpeg';
import cybersoftLogo from './assets/images/cybersoft.jpeg';
import hacettepeLogo from './assets/images/hacettepe.jpeg';
import capaLogo from './assets/images/capa.jpeg';

import {
  Container,
  Header,
  ProfileImage,
  Name,
  Title,
  SocialLinks,
  SocialLink,
  Section,
  SectionTitle,
  Content,
  ExperienceItem,
  CompanyLogo,
  ExperienceContent,
  ExperienceTitle,
  ExperienceCompany,
  ExperienceDate,
  ExperienceLocation,
  EducationItem,
  SchoolLogo,
  EducationContent,
  EducationTitle,
  EducationDescription,
  EducationDate,
  SkillCategory,
  SkillCategoryTitle,
  SkillsContainer,
  SkillTag,
  Footer,
  Copyright,
  LanguageToggle
} from './styles/App.styles';

const App = () => {
  const { t, i18n } = useTranslation();

  const calculateDuration = useCallback((startDate, endDate = null) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    const yearText = years > 0 ? `${years} ${t('experience.years')}` : '';
    const monthText = months > 0 ? `${months} ${t('experience.months')}` : '';
    const separator = years > 0 && months > 0 ? ' ' : '';

    return `${yearText}${separator}${monthText}`;
  }, [t]);

  const ExperienceDuration = ({ startDate, endDate }) => {
    const duration = useMemo(() => calculateDuration(startDate, endDate), 
      [startDate, endDate]);
    
    return (
      <ExperienceDate>
        {(() => {
          const [year, month] = startDate.split('-');
          return `${t(`experience.month_${parseInt(month)}`)} ${year}`;
        })()} - 
        {endDate ? 
          (() => {
            const [year, month] = endDate.split('-');
            return `${t(`experience.month_${parseInt(month)}`)} ${year}`;
          })() : 
          t('experience.current')
        } · {duration}
      </ExperienceDate>
    );
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === 'tr' ? 'en' : 'tr';
    i18n.changeLanguage(newLang);
  };

  return (
    <Container>
      <Header>
        <ProfileImage src={profileImage} alt="Ali Ömer Şimşek" />
        <Name>Ali Ömer Şimşek</Name>
        <Title>{t('header.title')}</Title>
        <SocialLinks>
          <SocialLink href="https://github.com/aliomersimsek" target="_blank" rel="noopener noreferrer" title="GitHub">
            <FaGithub />
          </SocialLink>
          <SocialLink href="https://tr.linkedin.com/in/aliomersimsek" target="_blank" rel="noopener noreferrer" title="LinkedIn">
            <FaLinkedin />
          </SocialLink>
          <SocialLink href="mailto:aliomersimsek@gmail.com" title="E-posta">
            <FaEnvelope />
          </SocialLink>
        </SocialLinks>
        <LanguageToggle onClick={toggleLanguage} title={i18n.language === 'tr' ? 'Switch to English' : 'Türkçe\'ye geç'}>
          <FaLanguage />
          <span>{i18n.language === 'tr' ? 'EN' : 'TR'}</span>
        </LanguageToggle>
      </Header>

      <Section>
        <SectionTitle>{t('about.title')}</SectionTitle>
        <Content>{t('about.content')}</Content>
      </Section>

      <Section>
        <SectionTitle>{t('experience.title')}</SectionTitle>
        <ExperienceItem>
          <CompanyLogo src={getirfinansLogo} alt="getirfinans logo" />
          <ExperienceContent>
            <ExperienceTitle>Software Engineer</ExperienceTitle>
            <ExperienceCompany>getirfinans</ExperienceCompany>
            <ExperienceDuration startDate="2022-03-07" />
            <ExperienceLocation>Ankara, Turkey</ExperienceLocation>
          </ExperienceContent>
        </ExperienceItem>

        <ExperienceItem>
          <CompanyLogo src={comodoLogo} alt="Comodo logo" />
          <ExperienceContent>
            <ExperienceTitle>Software Developer</ExperienceTitle>
            <ExperienceCompany>Comodo</ExperienceCompany>
            <ExperienceDuration startDate="2018-08-06" endDate="2022-02-25" />
            <ExperienceLocation>Ankara, Turkey</ExperienceLocation>
          </ExperienceContent>
        </ExperienceItem>

        <ExperienceItem>
          <CompanyLogo src={cybersoftLogo} alt="Cybersoft logo" />
          <ExperienceContent>
            <ExperienceTitle>Software Engineer</ExperienceTitle>
            <ExperienceCompany>Cybersoft</ExperienceCompany>
            <ExperienceDuration startDate="2017-02-13" endDate="2018-07-20" />
            <ExperienceLocation>Ankara, Turkey</ExperienceLocation>
          </ExperienceContent>
        </ExperienceItem>
      </Section>

      <Section>
        <SectionTitle>{t('education.title')}</SectionTitle>
        <EducationItem>
          <SchoolLogo src={hacettepeLogo} alt="Hacettepe Üniversitesi logo" />
          <EducationContent>
            <EducationTitle>{t('education.hacettepe.name')}</EducationTitle>
            <EducationDescription>{t('education.hacettepe.department')}</EducationDescription>
            <EducationDate>2011 - 2016</EducationDate>
          </EducationContent>
        </EducationItem>

        <EducationItem>
          <SchoolLogo src={capaLogo} alt="Çapa Fen Lisesi logo" />
          <EducationContent>
            <EducationTitle>{t('education.capa.name')}</EducationTitle>
            <EducationDescription>{t('education.capa.department')}</EducationDescription>
            <EducationDate>2006 - 2010</EducationDate>
          </EducationContent>
        </EducationItem>
      </Section>

      <Section>
        <SectionTitle>{t('skills.title')}</SectionTitle>
        
        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.programmingLanguages')}</SkillCategoryTitle>
          <SkillsContainer>
            {['Java', 'JavaScript', 'SQL', 'HTML', 'CSS'].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>

        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.frameworksLibraries')}</SkillCategoryTitle>
          <SkillsContainer>
            {['Spring Boot', 'React', 'Node.js', 'Hibernate', 'JPA'].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>

        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.awsServices')}</SkillCategoryTitle>
          <SkillsContainer>
            {[
              'AWS Lambda',
              'AWS Elastic Beanstalk',
              'AWS EC2',
              'AWS S3',
              'AWS SQS',
              'AWS CloudWatch',
              'AWS RDS',
              'AWS API Gateway',
              'AWS SNS',
              'AWS Step Functions'
            ].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>

        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.databases')}</SkillCategoryTitle>
          <SkillsContainer>
            {['MySQL', 'Sybase'].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>

        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.devopsTools')}</SkillCategoryTitle>
          <SkillsContainer>
            {['Docker', 'JIRA', 'Swagger'].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>

        <SkillCategory>
          <SkillCategoryTitle>{t('skills.categories.design')}</SkillCategoryTitle>
          <SkillsContainer>
            {['Adobe Illustrator', 'Adobe Photoshop', 'Web Design'].map((skill) => (
              <SkillTag key={skill}>{skill}</SkillTag>
            ))}
          </SkillsContainer>
        </SkillCategory>
      </Section>

      <Footer>
        <Copyright>{t('footer.copyright', { year: new Date().getFullYear() })}</Copyright>
      </Footer>
    </Container>
  );
};

export default App;