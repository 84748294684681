import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const Header = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  padding-top: 3rem;
  
  ${SocialLinks} {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  object-fit: cover;
  border: 3px solid rgba(0, 0, 0, 0.1);
`;

export const Name = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  color: #2c3e50;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: #7f8c8d;
  margin: 0.5rem 0;
  font-weight: 400;
`;

export const Section = styled.section`
  margin-bottom: 3rem;
`;

export const SectionTitle = styled.h3`
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

export const Content = styled.p`
  line-height: 1.6;
  color: #34495e;
`;

export const ExperienceItem = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const CompanyLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ExperienceContent = styled.div`
  flex: 1;
`;

export const ExperienceTitle = styled.h4`
  font-size: 1.2rem;
  color: #2c3e50;
  margin: 0;
`;

export const ExperienceCompany = styled.p`
  color: #7f8c8d;
  margin: 0.2rem 0;
`;

export const ExperienceDate = styled.p`
  color: #95a5a6;
  font-size: 0.9rem;
  margin: 0;
`;

export const ExperienceLocation = styled.p`
  color: #7f8c8d;
  font-size: 0.9rem;
  margin: 0.2rem 0;
  font-style: italic;
`;

export const EducationItem = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const SchoolLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const EducationContent = styled.div`
  flex: 1;
`;

export const EducationTitle = styled.h4`
  font-size: 1.2rem;
  color: #2c3e50;
  margin: 0;
`;

export const EducationDescription = styled.p`
  color: #7f8c8d;
  margin: 0.2rem 0;
  font-weight: 500;
`;

export const EducationDate = styled.p`
  color: #95a5a6;
  font-size: 0.9rem;
  margin: 0;
`;

export const SkillCategory = styled.div`
  margin-bottom: 2rem;
`;

export const SkillCategoryTitle = styled.h4`
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

export const SkillTag = styled.span`
  background-color: #3498db;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  font-size: 0.85rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(52, 152, 219, 0.3);
  }
`;

export const Footer = styled.footer`
  text-align: center;
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid #ecf0f1;
`;

export const Copyright = styled.p`
  color: #95a5a6;
  font-size: 0.8rem;
  margin: 0;
`;

export const SocialLink = styled.a`
  color: #2c3e50;
  font-size: 1.8rem;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    color: #3498db;
    transform: translateY(-2px);
  }
`;

export const LanguageToggle = styled.button`
  background: none;
  border: 1px solid #3498db;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #3498db;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  margin: 1rem auto 0;

  &:hover {
    background: #3498db;
    color: white;
  }

  svg {
    font-size: 1.2rem;
  }

  span {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;
